import React, {useState} from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import {  Link } from "react-router-dom";
import  Logo from "../assets/img/vasukamlogo.jpg"
import '../components/Product-Page/underline.css'





// import icons
import { FaBars } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';

const Navbar = () => {
  const [navMobile, setNavMobile] = useState(false);
  return (
    <header
      className='mt-0  z-20 relative px-4 lg:px-0 bg-black top-0'
      
    >
      <div className='container mx-auto p-6 '>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-[120px]'>
            {/* logo */}
            <a href='#'>
            <img src={Logo} class="h-16 sm:w-16 mr-3 sm:h-16" alt=" Logo" />
            </a>

            {/* nav / initially hidden / show on large screens  */}
            <div className='hidden lg:flex'>
            <ul className='flex gap-x-8 text-white'>
             <li className='navv'>
             <Link  to="/" smooth>Home</Link>
             </li>
             <li className='navv'>
            <Link to="/OurProduct" smooth>Our Products</Link>
             </li>
            
            <li className='navv'>
               <Link to="/Blog" smooth>Blogs</Link>
            </li>
            <li className='navv'>
             <a href="https://app.vasukam.com/ ">Community</a>
            </li>
           
            <li className='navv'>
             
            <Link to="/#abouts" smooth>About</Link>
             </li>
             
            
          </ul>
            </div>
          </div>

          {/* mobile nav / initially is showing / hide on large screens */}
          <div
            className={`${
              navMobile ? 'w-full bg-black text-white absolute z-40 top-[110px] left-0 flex justify-center text-center' : 'absolute left-[-100%] z-40 '
            } `}
          >
           <ul className='flex flex-col px-6 py-4 gap-y-2 '>
          <li className='navi text-2xl'>
             <Link  to="/" smooth>Home</Link>
             </li>
             <li className='navi text-2xl'>
               <Link to="/Blog" smooth>Blogs</Link>
            </li>
             <li className='navi text-2xl'>
            <Link to="/OurProduct" smooth>Our Products</Link>
             </li>
             <li className='navi text-2xl'>
            <a href="https://app.vasukam.com/" smooth>Community</a>
             </li>
            <li className='navi text-2xl'>
             <Link smooth>Abouts
              <a href="/#abouts"></a>
             </Link>
              </li>
            
          </ul>
          </div>

          <button className='btn btn-quaternary flex items-center gap-x-[6px] group'>
          <Link to="/Contact" smooth>Contact Us</Link>
            <BsArrowRight className='text-1xl text-accent-primary group-hover:text-white transition' />
          </button>

          {/* nav trigger btn / only shows on mobile screens */}
          <div
            onClick={() => setNavMobile(!navMobile)}
            className='text-2xl  text-primary cursor-pointer lg:hidden'
          >
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
