import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import Navbar from "../components/Navbar";

// npm i @emailjs/browser

const Result = () => {
    return(
        <p>Your message has been successfully sent. I will contact you soon</p>
    );
};

const Contact = () => {
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_cye2tmq", //services id
        "template_pef31b5", //template id
        e.target,
        "60FvBaSNJtchADCQM" //public key
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          
        },
        (error) => {
          console.log(error.text);
        }
      );
      e.target.reset();
      showResult(true);
  };

  // hide result

  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <>
    <Navbar />
    <div className="bg-white">
   
   
    <section  style={{ backgroundImage: ` url(${`images/contact.jpg`})` }} className="bg-red   min-h-screen  w-[100%] bg-cover py-5 ">
  <div className="relative  py-8 lg:py-16 px-4 mx-auto max-w-screen-md text-white bg-gradient-to-b from-black/80 to-black/40  backdrop-blur-lg border-[1px] border-solid border-white border-opacity-10 rounded-2xl shadow-[0_0_20px_10px_rgba(0,0,0,0.75)] ">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white dark:text-white">Contact Us</h2>
      <p className="mb-8 lg:mb-16 font-light text-center text-whitesm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p>
      <form action="#"  className="space-y-8 text-white bg-none [  ] "  onSubmit={sendEmail}>
          <div>
          <label for="subject" className="block mb-2 text-sm font-medium text-white">Name</label>
              <input type="text" name="user_name" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="May I Know Your Name" required /> 
             
          </div>
          <div>
          <label for="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
              <input name="user_email" type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="vasukam.connect@gmail.com" required />
          </div>
          <div className="sm:col-span-2">
              <label for="message" className="block mb-2 text-sm font-medium text-white">Your message</label>
              <textarea id="message" name="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
          </div>
          <button type="submit" value="Send" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-sky-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button>
          <div className="row">{result ?  <Result /> : null} </div>
      </form>
  </div>
</section>
</div>
    </>
  );
};

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 400px;
.whole{
  display:flex;
  justify-content: center;
  background: #ffffff;
}
  form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    
    input {
        width: 100%;
        height: 50px;
        margin-bottom: 6%;
        border: 0;
        margin-top: 2%;
        padding-left: 3%;
        background: #ffffff;
        -webkit-box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
        box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
        
      }
    }
    textarea {
        width: 100%;
        margin-bottom: 2%;
        border: 0;
        margin-top: 2%;
        height: 80px;
        color: black;
        -webkit-box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
        box-shadow: 0px 0px 48px 0px rgb(218, 218, 218);
        padding-left: 3%;
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
      width: 190px;
    height: 55px;
    border: 0;
    border-radius: 5px;
    background-color: #001517;
    -webkit-transition: 0.5s linear;
    -o-transition: 0.5s linear;
    transition: 0.5s linear;
    color: white;
    font-weight: bold;
    margin-top: 10%;
    }
    input[type="submit"]:hover {
        background-color: unset;
        border: 2px solid #e49504;
        -webkit-transition: 0.5s linear;
        -o-transition: 0.5s linear;
        transition: 0.5s linear;
        color: black;
    }

  }
`;
