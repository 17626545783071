import React from 'react'
import './Subscribe.css'

const Subscribe = () => {
    return (
        <div className='subscribe'>
            <div className="content">
                <h2>Join Our Vasukam Family</h2>
                <form action="https://getform.io/f/b67e957d-c8ee-49c5-abcd-b362e9c0b139" method="POST">
                    <div className="form-container display-col ">
                        <input type="email" name='email' placeholder='Enter your email'  />
                        <button className="btn m-4">Sign Up</button>
                    </div>
                    <div className="form-container">
                        <input type="checkbox"/> <p>Yes, I agree to receive email communications from VASUKAM.</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Subscribe