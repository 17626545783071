import React, { useState } from 'react';
import {  Link } from "react-router-dom";
// import components
import Logo from './assets/vasukamlogo.jpg';
import Nav from './Nav';
import NavMobile from './NavMobile';

// import icons
import { FaBars } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';

const Header = () => {
  const [navMobile, setNavMobile] = useState(false);
  return (
    <header
      className='mt-0  z-20 relative px-4 lg:px-0 bg-black top-0'
      data-aos='fade-down'
      data-aos-delay='1200'
      data-aos-duration='1000'
    >
      <div className='container mx-auto p-6 '>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-x-[120px]'>
            {/* logo */}
            <a href='#'>
            <img src={Logo} class="h-16 sm:w-16 mr-3 sm:h-16" alt=" Logo" />
            </a>

            {/* nav / initially hidden / show on large screens  */}
            <div className='hidden lg:flex'>
              <Nav />
            </div>
          </div>

          {/* mobile nav / initially is showing / hide on large screens */}
          <div
            className={`${
              navMobile ? 'w-full bg-black text-white absolute z-40 top-[90px] left-0 flex justify-center text-center' : 'absolute left-[-100%] z-40 '
            } `}
          >
            <NavMobile className= 'text-2xl'/>
          </div>

          <button className='btn btn-quaternary flex items-center gap-x-[5px] group'>
          <Link to="/Contact" smooth>Contact Us</Link>
            <BsArrowRight className='text-2xl text-accent-primary group-hover:text-white transition' />
          </button>

          {/* nav trigger btn / only shows on mobile screens */}
          <div
            onClick={() => setNavMobile(!navMobile)}
            className='text-2xl text-primary cursor-pointer lg:hidden'
          >
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
