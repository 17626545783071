// import icons
import { FaYoutube, FaInstagram, FaGithub, FaLinkedin } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from './assets/img/about.png';
import Feature1Img from './assets/img/features/feature1.png';
import Feature2Img from './assets/img/features/feature2.png';
import Feature3Img from './assets/img/features/feature3.png';
import Feature4Img from './assets/img/features/feature4.png';
import Avatar1Img from './assets/img/testimonials/avatar1.png';
import Avatar2Img from './assets/img/testimonials/avatar2.png';
import Avatar3Img from './assets/img/testimonials/avatar3.png';
import Logo from './assets/img/logo.png';
import HeroImage from './assets/img/hero-img.png';
import Feature1BgImg from './assets/img/features/feature1_bg.png';
import Feature2BgImg from './assets/img/features/feature2_bg.png';
import Feature3BgImg from './assets/img/features/feature3_bg.png';
import Feature4BgImg from './assets/img/features/feature4_bg.png';

export const navigationData = [
  {
    name: 'Home',
    href: '/',
    
  },
  {
    name: 'Our Products',
    href: '/OurProduct',
  },
  {
    name: 'Blogs',
    href: '/Blog',
  },
  {
    name: 'Community',
    href: 'https://app.vasukam.com/',
  },
  {
    name: 'About',
    href: '/',
  },
];

export const heroData = {
  title: `Products That We Deliver`,
  subtitle:
    'Our performance is your success. Our passion is innovation. Our expertise is unmatched. We get you more',
  btnText: 'Get Started',
  image: HeroImage,
};

export const aboutData = {
  image: AboutImg,
  title: 'Find Out A Little More About Us',
  subtitle:
    'Owing to this to be Vasukam aims to bring people closer to each other as the company’s name is inspired by the Sanskrit phrase ‘Vasudhaiva Kutumbakam’ meaning ‘The World Is One Family’                                      Vasukam inspires the creators to see the world differently, discover new interests, express themselves and own their space through the application named Ownsfare And More To Come.',
};

export const featuresData = {
  title: 'Some Of Our Products That We Offer',
  subtitle:
    'With our apps you can view the route of your Innovative minds and Building better networks, from our local headquarters to the place where you are. Look for the app now!',
  list: [
   
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: 'OwnsFare',
      description:
        'It’s not unusual to see people coming to Ownsfare to chat, meet up or even work. Our app is a neighborhood gathering place, a part of your daily routine......',
      linkText: 'Learn more',
      href: 'https://www.ownsfare.com/about',
      delay: '700',
    },
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: 'E-Commerce',
      description:
        'Pay with a Visa or PayPal card and without much ado',
      linkText: 'Learn more',
      delay: '400',
    },
    {
      image: Feature3Img,
      bgImage: Feature3BgImg,
      title: 'Teenzo',
      description:
        'Print out service gives you convenience if someday you need print data, just edit it all and just print it.',
      linkText: 'Learn more',
      delay: '1000',
    },
    {
      image: Feature4Img,
      bgImage: Feature4BgImg,
      title: 'More Products To Come',
      description:
        'A Lot Of Innovative Minds Are On The Way To Bring Some More New Things Towards You...',
      linkText: 'Learn more',
      delay: '1300',
    },
  ],
};

export const testimonialsData = [
  {
    image: Avatar1Img,
    name: 'Serena',
    web: 'rena.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '300',
  },
  {
    image: Avatar2Img,
    name: 'Natalia',
    web: 'nataliya.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '600',
  },
  {
    image: Avatar3Img,
    name: 'Vebin',
    web: 'vebin.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '900',
  },
];

export const ctaData = {
  title: 'Try Our OwnsFare App Now',
  subtitle: 'Get limited 1 week free try our features!',
  btnText1: '',
  btnText2: '',
};

export const footerData = {
  logo: Logo,
  address: 'Vasukam, New Delhi, Delhi IN',
  email: 'vasukam.connect@gmail.com',
  phone: '1-232-7788 (Main)',
  list1: [
    {
      name: 'Profile',
      href: '#',
    },
    {
      name: 'Features',
      href: '#',
    },
    {
      name: 'Careers',
      href: '#',
    },
    {
      name: 'DW News',
      href: '#',
    },
  ],
  list2: [
    {
      name: 'Support',
      href: '#',
    },
    {
      name: 'Sign Up',
      href: '#',
    },
    {
      name: 'Guide',
      href: '#',
    },
    {
      name: 'Reports',
      href: '#',
    },
    {
      name: 'Q & A',
      href: '#',
    },
  ],
  socialList: [
    {
      icon: <FaYoutube />,
      href: 'https://www.youtube.com/channel/UC8y9pXEDdwvKn5Bbmsmf5EA',
    },
    {
      icon: <FaInstagram />,
      href: 'https://www.instagram.com/ownsfare/',
    },
    {
      icon: <FaLinkedin />,
      href: 'https://www.linkedin.com/company/vasukam/',
    },
  ],
};

export const copyrightData = {
  text: '© Product Texas, 2022. All rights reserved. Company Registration Number: 09833888.',
  icon: <BsChatDotsFill />,
};
