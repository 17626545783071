import React from 'react';

// import animate on scroll
import Aos from 'aos';
import 'aos/dist/aos.css';

// import components
import Hero from '../components/Product-Page/Hero';
import About from '../components/Product-Page/About';
import Features from '../components/Product-Page/Features';
import Testimonials from '../components/Product-Page/Testimonials';
import CtaSection from '../components/Product-Page/CtaSection';
import Footer from '../components/Product-Page/Footer';


const OurProduct = () => {
  // animate on scroll initialization
  Aos.init({
    duration: 800,
    offset: 0,
  });
  return (
    <div className='overflow-hidden'>
      <Hero />
      <About />
      <Features />
      <Testimonials />
      <CtaSection />
      <Footer />
    </div>
  );
};

export default OurProduct;
