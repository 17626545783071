import React from 'react';
import { SiHiveBlockchain, SiStrapi, SiFsecure } from 'react-icons/si';
import { VscServerProcess } from 'react-icons/vsc';
import AboutCard from './AboutCard';

const About = () => {
  return (
    <section id="abouts">
    <div  className="mt-0 bg-black">
    <div className='w-full bg-black text-white text-center'>

      <div className='max-w-[1240px] bg-black mx-auto top-0 '>
        <h1 className='blue pt-16 top-4 -mt-14 md:-mt-16' >About Us</h1>
        <div>
        <p className='text-2xl py-4 '>
        <span className='blue'> Working toward connecting </span>  whole world as a Family,
        </p>
          <p className='py-4 text-xl'>
          Vasudhaiva Kutumbakam is a Sanskrit phrase found in Hindu texts such as the Maha Upanishad, which means "The World Is One Family" Vedic tradition mentions "Vasudhaiva Kutumbakam" meaning all living beings on the earth are a family.
          </p>

          {/* Card Container */}
          <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-4 '>

            {/* Card */}
            <AboutCard icon={<SiHiveBlockchain size={40} />} heading='One Is A Relative, The Other Stranger,
Say The Small Minded.' text='' />

            <AboutCard icon={<SiFsecure size={40} />} heading='The Entire World Is A Family,
            Live The Magnanimous.' text='' />
            <AboutCard icon={<SiStrapi size={40} />} heading='Be Detached, Be Magnanimous,' text='' />
            <AboutCard icon={<VscServerProcess size={40} />} heading='Lift Up Your Mind, Enjoy The Fruit Of Brahmanic Freedom.' text='' />
          </div>
        </div>
      </div>
    </div>
    </div>
    </section>
  );
};

export default About;
