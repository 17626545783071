import { React, useState } from "react";
import { BrowserRouter , Routes, Route } from "react-router-dom";
import  Home from './components/Home';
import Product from './pages/OurProduct';
import "./App.css";
import Abouts from './components/About';
import Slider from "./Blog/Components/Slider";
import Contact from './pages/Contact'




function App() {
 
  return (
   <>
   <BrowserRouter>
    
      <Routes>
        <Route exact path='/'  element={<Home />} />
        <Route path='/OurProduct' element={<Product />} />
        <Route path='/Abouts/:id' element={<Abouts />} />
        
      
  <Route path='/Contact' element={<Contact />} /> 
 
  <Route path='/Blog' element={<Slider />} /> 
          

       </Routes>
      </BrowserRouter>
 

   </>
  );
}

export default App;
