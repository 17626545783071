import React from 'react';
import Typed from 'react-typed'
import heroVid from '../assets/video.mp4';
import { BsGooglePlay } from 'react-icons/bs';

const Hero = () => {
  return (
    <div className='w-full h-[90vh] top-[90px]'>
      <video
        className='object-cover h-screen w-full absolute -z-10'
        src={heroVid}
        autoPlay
        loop
        muted
      />
      <div className='w-full mb-18 h-[90%] flex flex-col justify-center items-center text-white px-4 text-center'>
        <h1 className='md:text-9xl text-7xl'>Vasukam</h1>
        <h1 className='py-2'>
          <span className='blue'>Vasudhaiva</span> </h1>
          <span><Typed className='p-2 text-3xl font-bold' 
                    strings={['Kutumbakam']}
                    typeSpeed={400}
                    
                /></span>
        
        
        <div className='m-6'>
          <a href="https://www.ownsfare.com/"> 
          <button className='m-2' href="https://www.ownsfare.com/">
          
           Ownsfare
         
          </button>
         </a>
           <a href="https://play.google.com/store/apps/details?id=com.vasukam.ownsfare"> 
           <button className='m-2 bg-black' href="https://play.google.com/store/apps/details?id=com.vasukam.ownsfare">
          
           <BsGooglePlay />
         
            </button>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
