import React from 'react';
import terminal from '../assets/terminal.jpg'
import './Developer.css'
const Developers = () => {
  return (
    <div className='w-full text-white bg-black'>
      <div className='max-w-[1240px] mx-auto px-4 py-16 md:flex'>
        <div>
          <h1>History Of Our Vasukam.</h1>
          <p className='p-5'>
          The original Verse appears in Chapter 6 of Maha Upanishad <span className="blue">it is considered the most important moral value in </span>, the <span className="blue"> Indian society</span>  This verse of Maha Upanishad is engraved in the entrance hall of the Parliament Of India Subsequent shlokas go on to say that those who have no attachments go on to find the Brahman (The One Supreme, Universal Spirit That Is The Origin And Support Of The Phenomenal Universe).
          </p>
          <h1>References In The Modern World.</h1>
          <p className='p-5'>
          India's Prime Minister <span className="blue">Narendra Modi </span>,used this phrase in a speech at World Culture Festival, organized by Art of Living, adding that <span className="blue">"Indian culture is very rich and has inculcated in each one of us with great values, we are the people who have come from Aham Brahmasmi to Vasudhaiva Kutumbakam, we are the people who have come from Upanishads to Upgraha.(Satellite)</span>  It was used in the logo of the 7th International Earth Science Olympiad, which was held in Mysore, India in 2013. It was designed to emphasize on the integration of the Earth’s subsystems in the school curriculum. It was designed by R. Shankar and Shwetha B. Shetty of Mangalore University
          </p>
        </div>
        <div className='flex justify-center w-full py-16   '>
            <img className='max-w-[260px] imgi' src={terminal} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default Developers;
